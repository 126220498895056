.portfolio_container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio_item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio_item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio_item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}
.portfolio_item h3 {
    margin: 1.2rem 0 1.5rem;
}
.portfolio_item h4 {
    margin: 1rem 0 2rem;
    color:#4db5ff;
    font-size: 0.7rem;
    
}
.portfolio_item h5 {
    margin: 1.5rem 0 1rem;
    color: antiquewhite;
    font-size: 0.8rem;
}

.portfolio_item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.images {
    width: 100%;
    height: 220px;
    object-fit:fill

}



/* ============= Medium devices =============== */
@media screen and (max-width: 1024px) {
    .portfolio_container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }

    
}

/* ============= small devices =============== */
@media screen and (max-width: 600px) {

    .portfolio_container {
            grid-template-columns: 1fr;
            gap: 1rem;
        }

}